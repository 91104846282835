import {Component, OnInit} from '@angular/core';

import {ProgressService} from './progress.service';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css']
})
export class ProgressComponent implements OnInit {

  progress?: boolean;

  constructor(private service: ProgressService) {
  }

  ngOnInit(): void {
    this.service.getProgress().subscribe((progress) => {
      this.progress = progress;
    });
  }

}
