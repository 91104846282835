import {Component} from '@angular/core';

@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html'
})
export class EntitiesComponent {

  constructor() {
  }

}
