import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';

// @ts-ignore
import io from 'socket.io-client';

import {MenuItem} from 'primeng/api';

import {MenuService} from './menu.service';
import {AuthService} from '../../service/auth.service';
import {LoginService} from '../../../pages/login/login.service';
import {ToastService, Severity} from '../toast/toast.service';

import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  items: Array<MenuItem> = new Array<MenuItem>();

  type?: string;
  socket_cacbonat_active: number = 0;

  changePassword?: boolean;
  changePasswordForm = this.fb.group({
    newPassword: ['', Validators.required],
    newPasswordVerify: ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private menuService: MenuService,
    private authService: AuthService,
    private loginService: LoginService,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    this.authService.getMe().subscribe(({username, type, socket_cacbonat_active}) => {
      this.type = type;
      this.socket_cacbonat_active = socket_cacbonat_active;
      switch (type) {
        case 'boss':
          this.menuService.getBossMenus().then((items) => {
            this.items = items;
          });
          break;
        case 'admin':
          this.menuService.getAdminMenus().then((items) => {
            this.items = items;
          });
          break;
        case 'user':
          this.menuService.getUserMenus().then((items) => {
            this.items = items;
          });
          if (username) {
            const socket = io({
              path: `${environment.baseURL}/socket.io`,
              query: {
                type: 'web_browser',
                token: username
              }
            });
            socket.on('socket_cacbonat_active', (data: number) => {
              this.socket_cacbonat_active = data;
            });
          }
          break;
      }
    });
  }

  setPassword(): void {
    if (this.changePassword && this.changePasswordForm.valid
      && this.changePasswordForm.get('newPassword')?.value === this.changePasswordForm.get('newPasswordVerify')?.value) {
      this.loginService.setPassword(this.changePasswordForm.get('newPassword')?.value).subscribe(() => {
        this.toastService.setMessage({severity: Severity.SUCCESS});
        this.changePassword = false;
      });
    }
  }

  logout(): void {
    this.loginService.logout().subscribe(() => {
      this.router.navigateByUrl('/login').then();
    });
  }

}
