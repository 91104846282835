import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  private readonly event: BehaviorSubject<any>;

  constructor() {
    this.event = new BehaviorSubject<any>(null);
  }

  getEvent(): BehaviorSubject<any> {
    return this.event;
  }

  setEvent(event: any): void {
    this.event.next(event);
  }

}
