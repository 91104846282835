import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) {
  }

  login(item: any): Observable<any> {
    return this.http.post('login', item);
  }

  setPassword(newPassword: string): Observable<any> {
    return this.http.post('set-password', {newPassword});
  }

  logout(): Observable<any> {
    return this.http.get('logout');
  }

}
