import {Injectable} from '@angular/core';

import languages from './languages';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  locale: string = 'vi';

  constructor() {
    const locale = localStorage.getItem('son_locale');
    if (locale) {
      this.locale = locale;
    }
  }

  setLocale(locale: string): void {
    if (locale) {
      this.locale = locale;
      localStorage.setItem('son_locale', locale);
      location.reload();
    }
  }

  get(value?: string): string {
    if (value && value.includes('+')) {
      let result = '';
      value.split('+').forEach((text) => {
        result += ` ${this.get(text)}`;
      });
      return result.trim();
    } else if (value && languages[value] && languages[value][this.locale] !== undefined) {
      return languages[value][this.locale];
    }
    return value || '';
  }
}
