import {Injectable} from '@angular/core';

import {MenuItem} from 'primeng/api';

import {TranslateService} from '../../translate/translate.service';
import {ItemsService as MeService} from '../../../entities/user/me/items.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private translateService: TranslateService,
    private meService: MeService
  ) {
  }

  getBossMenus(): Promise<Array<MenuItem>> {
    return new Promise((resolve) => {
      resolve([
        {
          label: this.translateService.get('UserAccount'),
          icon: 'fas fa-users',
          routerLink: '/boss/user-accounts'
        },
        {
          label: this.translateService.get('Seller'),
          icon: 'fas fa-store',
          routerLink: '/boss/sellers'
        },
        {
          label: this.translateService.get('Product'),
          icon: 'fas fa-list-ul',
          routerLink: '/boss/products'
        },
        {
          label: this.translateService.get('Đấu giá'),
          icon: 'fas fa-broadcast-tower',
          routerLink: '/boss/prices'
        },
        {
          label: this.translateService.get('Price'),
          icon: 'fas fa-hand-holding-usd',
          routerLink: '/boss/set-prices'
        },
        {
          label: this.translateService.get('Logs'),
          icon: 'fas fa-signal',
          routerLink: '/boss/logs'
        },
        {
          label: this.translateService.get('Satellites'),
          icon: 'fas fa-satellite-dish',
          routerLink: '/boss/satellites'
        },
        {
          label: this.translateService.get('Heartbeat'),
          icon: 'fas fa-heartbeat',
          routerLink: '/boss/heartbeat'
        }
      ]);
    });
  }

  getAdminMenus(): Promise<Array<MenuItem>> {
    return new Promise((resolve) => {
      resolve([
        {
          label: this.translateService.get('UserAccount'),
          icon: 'fas fa-users',
          routerLink: '/admin/user-accounts'
        },
        {
          label: this.translateService.get('Seller'),
          icon: 'fas fa-store',
          routerLink: '/admin/sellers'
        },
        {
          label: this.translateService.get('Payment'),
          icon: 'far fa-money-bill-alt',
          routerLink: '/admin/payments'
        }
      ]);
    });
  }

  getUserMenus(): Promise<Array<MenuItem>> {
    return new Promise((resolve) => {
      this.meService.getUserPayments('{}').subscribe((response) => {
        let menus = [
          {
            index: 99,
            label: this.translateService.get('UserAccount'),
            icon: 'fas fa-user',
            routerLink: '/user/me'
          }
        ];
        let guide: any = {
          index: 99,
          label: this.translateService.get('Hướng dẫn sử dụng'),
          icon: 'fas fa-book',
          items: []
        };
        guide.items.push({
          index: 1,
          label: this.translateService.get('Tính năng Xác nhận đơn / In đơn'),
          routerLink: '/user/guide/guide1'
        });
        guide.items.push({
          index: 2,
          label: this.translateService.get('Tính năng Đấu giá'),
          routerLink: '/user/guide/guide2'
        });
        guide.items.push({
          index: 3,
          label: this.translateService.get('Tính năng Lên Deal tự động'),
          routerLink: '/user/guide/guide3'
        });
        guide.items.push({
          index: 4,
          label: this.translateService.get('Các tính năng Báo cáo'),
          routerLink: '/user/guide/guide4'
        });
        guide.items.push({
          index: 5,
          label: this.translateService.get('FAQ'),
          routerLink: '/user/guide/guide5'
        });
        menus.push(guide);
        if (response && response.items && response.items.length) {
          let ids = new Set<string>();
          response.items.forEach((item: any) => {
            if (item && item.user_group && item.user_group.user_group_id) {
              ids.add(item.user_group.user_group_id);
            }
          });

          let has_prds = false;
          ids.forEach((id) => {
            if (id.includes('report') || ['AUP_USERS'].includes(id)) {
              has_prds = true;
            }
          });
          if (has_prds) {
            menus.push({
              index: 3,
              label: this.translateService.get('Product'),
              icon: 'fas fa-list-ul',
              routerLink: '/user/products'
            });
          }

          let report: any = null;
          ids.forEach((id) => {
            if (id.toString().includes('report') || id === 'AUP_USERS') {
              report = {};
            }
          });
          if (report) {
            report = {
              index: 98,
              label: this.translateService.get('Report'),
              icon: 'fas fa-balance-scale-right',
              items: []
            };
            menus.push(report);
          }
          ids.forEach((id) => {
            switch (id) {
              case 'CPO_USERS':
                menus.push(
                  {
                    index: 1,
                    label: this.translateService.get('Order'),
                    icon: 'fas fa-user',
                    routerLink: '/user/orders'
                  },
                  {
                    index: 2,
                    label: this.translateService.get('Lịch xác nhận'),
                    icon: 'far fa-clock',
                    routerLink: '/user/schedules'
                  }
                );
                break;
              case 'AUP_USERS':
                menus.push(
                  {
                    index: 6,
                    label: this.translateService.get('Đấu giá'),
                    icon: 'fas fa-broadcast-tower',
                    routerLink: '/user/prices'
                  },
                  {
                    index: 7,
                    label: this.translateService.get('Set giá'),
                    icon: 'fas fa-hand-holding-usd',
                    routerLink: '/user/set-prices'
                  }
                );
                report.items.push({
                  index: 13,
                  label: this.translateService.get('Sản phẩm không đấu giá'),
                  routerLink: '/user/report/report13'
                });
                break;
              case 'CAMPAIGNS_USERS':
                menus.push({
                  index: 90,
                  label: this.translateService.get('Chiến dịch khuyến mãi'),
                  icon: 'fas fa-medal',
                  routerLink: '/user/campaigns'
                });
                break;
              case 'report3':
                report.items.push({
                  index: 3,
                  label: this.translateService.get('Giá bán'),
                  routerLink: '/user/report/report3'
                });
                break;
              case 'report5':
                report.items.push({
                  index: 5,
                  label: this.translateService.get('Giá đối thủ nhỏ hơn min'),
                  routerLink: '/user/report/report5'
                });
                break;
              case 'report6':
                report.items.push({
                  index: 6,
                  label: this.translateService.get('Giá đối thủ lớn hơn max'),
                  routerLink: '/user/report/report6'
                });
                break;
              case 'report7':
                report.items.push({
                  index: 7,
                  label: this.translateService.get('Báo cáo Top'),
                  routerLink: '/user/report/report7'
                });
                break;
              case 'report8':
                report.items.push({
                  index: 8,
                  label: this.translateService.get('Báo cáo Best Sales'),
                  routerLink: '/user/report/report8'
                });
                break;
              case 'report9':
                report.items.push({
                  index: 9,
                  label: this.translateService.get('Báo cáo Rating'),
                  routerLink: '/user/report/report9'
                });
                break;
              case 'report12':
                report.items.push({
                  index: 12,
                  label: this.translateService.get('Báo cáo đối thủ'),
                  routerLink: '/user/report/report12'
                });
                break;
              case 'report14':
                report.items.push({
                  index: 14,
                  label: this.translateService.get('Tồn đối thủ'),
                  routerLink: '/user/report/report14'
                });
                break;
              case 'report15':
                report.items.push({
                  index: 14,
                  label: this.translateService.get('Báo cáo đánh giá'),
                  routerLink: '/user/report/report15'
                });
                break;
            }
          });
          if (report && report.items) {
            report.items.sort((a: any, b: any) => {
              return a.index - b.index
            });
          }
        }
        menus.sort((a, b) => {
          return a.index - b.index
        });
        resolve(menus);
      });
    });
  }


}
