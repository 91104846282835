const languages: any = {
  Oops: {
    en: 'Oops...',
    vi: 'Có lỗi xảy ra...'
  },
  ServiceUnavailable: {
    en: 'Service Unavailable',
    vi: 'Lỗi kết nối, vui lòng thử lại sau'
  },
  Unauthorized: {
    en: '401 Unauthorized',
    vi: 'Phiên đăng nhập đã hết hạn'
  },
  Forbidden: {
    en: '403 Forbidden',
    vi: 'Bạn không có quyền truy cập'
  },
  NotFound: {
    en: '404 Not Found',
    vi: 'Dữ liệu không tồn tại'
  },
  SignIn: {
    en: 'Sign in',
    vi: 'Đăng nhập'
  },
  Logout: {
    en: 'Logout',
    vi: 'Đăng xuất'
  },
  ForgotPassword: {
    en: 'Forgot password?',
    vi: 'Quên mật khẩu?'
  },
  NeedHelp: {
    en: 'Need help?',
    vi: 'Cần hỗ trợ?'
  },
  Retry: {
    en: 'Retry',
    vi: 'Thử lại'
  },
  IncorrectPasswordError: {
    en: 'Password or username is incorrect',
    vi: 'Tên đăng nhập hoặc mật khẩu không chính xác!'
  },
  IncorrectUsernameError: {
    en: 'Password or username is incorrect',
    vi: 'Tên đăng nhập hoặc mật khẩu không chính xác!'
  },
  AttemptTooSoonError: {
    en: 'Account is currently locked. Try again later',
    vi: 'Tài khoản hiện đang bị khóa. Thử lại sau!'
  },
  AccountDisabled: {
    en: 'Account Disabled',
    vi: 'Tài khoản bị khóa!'
  },
  Disabled: {
    en: 'Disabled',
    vi: 'Khóa'
  },
  DataSavedSuccessfully: {
    en: 'Data saved successfully!',
    vi: 'Dữ liệu đã được cập nhật!'
  },
  Success: {
    en: 'Success',
    vi: 'Thành công!'
  },
  Home: {
    en: 'Home',
    vi: 'Trang chủ'
  },
  Sales: {
    en: 'Sales',
    vi: 'Bán hàng'
  },
  Purchases: {
    en: 'Purchases',
    vi: 'Mua hàng'
  },
  Logistics: {
    en: 'Logistics',
    vi: 'Kho vận'
  },
  Profile: {
    en: 'Profile',
    vi: 'Thông tin cá nhân'
  },
  Dashboard: {
    en: 'Dashboard',
    vi: 'Tổng quan'
  },
  CreateOrder: {
    en: 'Create Order',
    vi: 'Tạo đơn hàng'
  },
  Order: {
    en: 'Order',
    vi: 'Đơn hàng'
  },
  Customer: {
    en: 'Customer',
    vi: 'Khách hàng'
  },
  Cogs: {
    en: 'cogs',
    vi: 'Giá vốn'
  },
  SalesOrders: {
    en: 'Sales Orders',
    vi: 'Đơn hàng bán'
  },
  PurchasesOrders: {
    en: 'Purchases Orders',
    vi: 'Đơn hàng mua'
  },
  Report: {
    en: 'Report',
    vi: 'Báo cáo'
  },
  Revenue: {
    en: 'Revenue',
    vi: 'Doanh thu'
  },
  Product: {
    en: 'Product',
    vi: 'Sản phẩm'
  },
  Supplier: {
    en: 'Supplier',
    vi: 'Nhà cung cấp'
  },
  TransferShipment: {
    en: 'Transfer Shipment',
    vi: 'Điều chuyển'
  },
  InventoryAssets: {
    en: 'Inventory Assets',
    vi: 'Tồn kho'
  },
  PhysicalChanges: {
    en: 'Physical Changes',
    vi: 'Thay đổi vật lí'
  },
  VarianceReason: {
    en: 'Variance Reason',
    vi: 'Nguyên nhân'
  },
  QuantityChange: {
    en: 'Quantity Change',
    vi: 'SL thay đổi'
  },
  Comments: {
    en: 'Comments',
    vi: 'Ghi chú'
  },
  Back: {
    en: 'Back',
    vi: 'Trở lại'
  },
  Detail: {
    en: 'Detail',
    vi: 'Chi tiết'
  },
  Add: {
    en: 'Add',
    vi: 'Thêm'
  },
  Save: {
    en: 'Save',
    vi: 'Lưu'
  },
  Close: {
    en: 'Close',
    vi: 'Đóng'
  },
  Create: {
    en: 'Create',
    vi: 'Tạo mới'
  },
  Edit: {
    en: 'Edit',
    vi: 'Chỉnh sửa'
  },
  Update: {
    en: 'Update',
    vi: 'Cập nhật'
  },
  Delete: {
    en: 'Delete',
    vi: 'Xóa'
  },
  Lock: {
    en: 'Lock',
    vi: 'Khóa'
  },
  Unlock: {
    en: 'Unlock',
    vi: 'Mở khóa'
  },
  ProductId: {
    en: 'Product Id',
    vi: 'Mã sản phẩm'
  },
  ProductName: {
    en: 'Product Name',
    vi: 'Tên sản phẩm'
  },
  Description: {
    en: 'Description',
    vi: 'Mô tả'
  },
  Status: {
    en: 'Status',
    vi: 'Trạng thái'
  },
  FieldIsRequired: {
    en: 'Field is required.',
    vi: 'Trường không được để trống.'
  },
  ContactNumberInvalid: {
    en: 'Contact number invalid.',
    vi: 'Số điện thoại không hợp lệ.'
  },
  Search: {
    en: 'Search',
    vi: 'Tìm kiếm'
  },
  SearchProduct: {
    en: 'Search Product',
    vi: 'Tìm sản phẩm'
  },
  SearchCustomer: {
    en: 'Search Customer',
    vi: 'Tìm khách hàng'
  },
  unitListPrice: {
    en: 'Unit List Price',
    vi: 'Giá niêm yết'
  },
  unitAmount: {
    en: 'Unit Amount',
    vi: 'Giá hiện tại'
  },
  Amount: {
    en: 'Amount',
    vi: 'Số tiền'
  },
  Quantity: {
    en: 'Quantity',
    vi: 'Số lượng'
  },
  itemTotal: {
    en: 'Item Total',
    vi: 'Thành tiền'
  },
  Information: {
    en: 'Information',
    vi: 'Thông tin'
  },
  facility: {
    en: 'Facility',
    vi: 'Kho hàng'
  },
  ProductStore: {
    en: 'Product Store',
    vi: 'Cửa hàng'
  },
  storeName: {
    en: 'Store name',
    vi: 'Tên cửa hàng'
  },
  quantityOnHandTotal: {
    en: 'Quantity on hand total',
    vi: 'Số lượng tồn (QOH)'
  },
  QOHDiff: {
    en: 'QOH Diff',
    vi: 'QOH thay đổi'
  },
  availableToPromiseTotal: {
    en: 'Available to promise total',
    vi: 'Số lượng có thể xuất(ATP)'
  },
  ATPDiff: {
    en: 'ATP Diff',
    vi: 'ATP thay đổi'
  },
  NotAvailable: {
    en: 'Not Available',
    vi: 'Không có sẵn'
  },
  NotIssued: {
    en: 'Not Issued',
    vi: 'Chưa xuất'
  },
  Source: {
    en: 'Source',
    vi: 'Nguồn'
  },
  grandTotal: {
    en: 'Grand Total',
    vi: 'Tổng cộng'
  },
  quantityTotal: {
    en: 'Quantity Total',
    vi: 'Tổng số'
  },
  pseudoId: {
    en: 'Pseudo Id',
    vi: 'Mã'
  },
  placedDate: {
    en: 'Placed Date',
    vi: 'Ngày đặt'
  },
  facilityName: {
    en: 'Facility Name',
    vi: 'Tên kho'
  },
  Geo: {
    en: 'Geo',
    vi: 'Địa phương'
  },
  Address: {
    en: 'Address',
    vi: 'Địa chỉ'
  },
  sequenceNum: {
    en: 'SequenceNum',
    vi: 'Số thứ tự'
  },
  SupplierNotFound: {
    en: 'Supplier Not Found',
    vi: 'Không tìm thấy nhà cung cấp'
  },
  PriceNotFound: {
    en: 'Price Not Found',
    vi: 'Không tìm thấy giá sản phẩm'
  },
  ProductNotFound: {
    en: 'Product Not Found',
    vi: 'Không tìm thấy sản phẩm'
  },
  ProductAlreadyExists: {
    en: 'Product Already Exists',
    vi: 'Sản phẩm đã tồn tại'
  },
  Picklist: {
    en: 'Picklist',
    vi: 'Soạn hàng'
  },
  OutgoingShipment: {
    en: 'Outgoing Shipment',
    vi: 'Xuất hàng'
  },
  IncomingShipment: {
    en: 'Incoming Shipment',
    vi: 'Nhập hàng'
  },
  Content: {
    en: 'Content',
    vi: 'Nội dung'
  },
  Associated: {
    en: 'Associated',
    vi: 'Liên kết'
  },
  Categories: {
    en: 'Categories',
    vi: 'Danh mục'
  },
  Prices: {
    en: 'Prices',
    vi: 'Giá'
  },
  Identification: {
    en: 'Identification',
    vi: 'Mã vạch'
  },
  Features: {
    en: 'Features',
    vi: 'Đặc điểm'
  },
  Brand: {
    en: 'Brand',
    vi: 'Thương hiệu'
  },
  ProductImage: {
    en: 'Product Image',
    vi: 'Ảnh sản phẩm'
  },
  ProductImageDetail: {
    en: 'Product Image Detail',
    vi: 'Album ảnh sản phẩm'
  },
  Placed: {
    en: 'Placed',
    vi: 'Đã lập'
  },
  Shipped: {
    en: 'Shipped',
    vi: 'Đã giao'
  },
  Approved: {
    en: 'Approved',
    vi: 'Đã duyệt'
  },
  Approve: {
    en: 'Approve',
    vi: 'Duyệt'
  },
  Sent: {
    en: 'Sent',
    vi: 'Đã gửi'
  },
  Completed: {
    en: 'Completed',
    vi: 'Hoàn thành'
  },
  DateTime: {
    en: 'DateTime',
    vi: 'Thời gian'
  },
  Employee: {
    en: 'Employee',
    vi: 'Nhân viên'
  },
  DestinationFacility: {
    en: 'Destination Facility',
    vi: 'Kho nhận'
  },
  Phone: {
    en: 'Phone',
    vi: 'Số điện thoại'
  },
  Email: {
    en: 'Email',
    vi: 'Email'
  },
  CustomerClass: {
    en: 'Customer Class',
    vi: 'Loại khách hàng'
  },
  FullName: {
    en: 'Full Name',
    vi: 'Họ và tên'
  },
  birthDate: {
    en: 'BirthDate',
    vi: 'Sinh nhật'
  },
  gender: {
    en: 'Gender',
    vi: 'Giới tính'
  },
  ShippingAddress: {
    en: 'Shipping Address',
    vi: 'Địa chỉ giao hàng'
  },
  address1: {
    en: 'Address',
    vi: 'Số nhà, đường'
  },
  stateProvinceGeoId: {
    en: 'State Province',
    vi: 'Tỉnh/thành phố'
  },
  countyGeoId: {
    en: 'County',
    vi: 'Quận/huyện'
  },
  PostalTrust: {
    en: 'Postal Trust',
    vi: 'Tin cậy'
  },
  toName: {
    en: 'To Name',
    vi: 'Người nhận'
  },
  Select: {
    en: 'Select',
    vi: 'Chọn'
  },
  receivedDate: {
    en: 'Received Date',
    vi: 'Ngày nhận'
  },
  FromShipment: {
    en: 'From Shipment',
    vi: 'Từ mã nhập'
  },
  FromOrder: {
    en: 'From Order',
    vi: 'Từ đơn mua'
  },
  acquireCost: {
    en: 'Acquire Cost',
    vi: 'Chi phí mua'
  },
  Reservation: {
    en: 'Reservation',
    vi: 'Gom hàng'
  },
  Date: {
    en: 'Date',
    vi: 'Ngày'
  },
  CreatedDate: {
    en: 'Created Date',
    vi: 'Ngày tạo'
  },
  FromDate: {
    en: 'From Date',
    vi: 'Từ ngày'
  },
  ThruDate: {
    en: 'Thru Date',
    vi: 'Đến ngày'
  },
  AssetDetailHistory: {
    en: 'Asset Detail History',
    vi: 'Lịch sử xuất nhập'
  },
  Receipt: {
    en: 'Receipt',
    vi: 'Phiếu nhập'
  },
  Issuance: {
    en: 'Issuance',
    vi: 'Phiếu xuất'
  },
  ReservationRemoved: {
    en: 'Reservation Removed',
    vi: 'Gom hàng đã hủy'
  },
  MoveFromAsset: {
    en: 'Move From Asset',
    vi: 'Chuyển từ tồn kho'
  },
  ConsumerPrices: {
    en: 'Consumer Prices',
    vi: 'Giá bán'
  },
  SupplierPrices: {
    en: 'Supplier Prices',
    vi: 'Giá mua'
  },
  ShipMethod: {
    en: 'Ship Method',
    vi: 'Phương thức giao hàng'
  },
  Ship: {
    en: 'Ship',
    vi: 'Giao hàng'
  },
  SalesChannel: {
    en: 'Sales Channel',
    vi: 'Kênh bán hàng'
  },
  WaitForConfirm: {
    en: 'Wait for confirm',
    vi: 'Cần xác nhận'
  },
  PrintDeliveryNote: {
    en: 'Print delivery note',
    vi: 'In phiếu giao hàng'
  },
  OrderID: {
    en: 'Order ID',
    vi: 'Mã đơn hàng'
  },
  SendOrReturnItems: {
    en: 'Send / Return items',
    vi: 'Gửi / trả hàng'
  },
  ConfirmationDate: {
    en: 'Confirmation Date',
    vi: 'Ngày xác nhận'
  },
  InventoryRequisitionId: {
    en: 'Inventory Requisition Id',
    vi: 'Phiếu gửi'
  },
  Print: {
    en: 'Print',
    vi: 'In'
  },
  PrintMinCode: {
    en: 'Print MinCode',
    vi: 'In MinCode'
  },
  PrintSelectedOrders: {
    en: 'Print Selected Orders',
    vi: 'In đơn đã chọn'
  },
  PrintNotPrintedYet: {
    en: 'Print Not Printed Yet',
    vi: 'In tất cả đơn chưa in'
  },
  AllOrdersArePrinted: {
    en: 'All Orders Are Printed',
    vi: 'Tất cả đơn đã in'
  },
  IsPrinted: {
    en: 'Is Printed',
    vi: 'Đã in'
  },
  Deadline: {
    en: 'Deadline',
    vi: 'Hạn chót'
  },
  ConfirmSelectedOrders: {
    en: 'Confirm Selected Orders',
    vi: 'Xác nhận đơn đã chọn'
  },
  ConfirmAll: {
    en: 'Confirm All',
    vi: 'Xác nhận tất cả'
  },
  AllOrdersAreConfirmed: {
    en: 'All Orders Are Confirmed',
    vi: 'Tất cả đơn đã Xác nhận'
  },
  Organization: {
    en: 'Organization',
    vi: 'Tổ chức'
  },
  Synchronization: {
    en: 'Synchronization',
    vi: 'Đồng bộ'
  },
  SyncSelectedOrders: {
    en: 'Sync Selected Orders',
    vi: 'Đồng bộ đơn đã chọn'
  },
  UserAccount: {
    en: 'User Account',
    vi: 'Tài khoản'
  },
  Username: {
    en: 'Username',
    vi: 'Tài khoản'
  },
  Password: {
    en: 'Password',
    vi: 'Mật khẩu'
  },
  IncludeFacility: {
    en: 'Include Facility',
    vi: 'Bao gồm kho hàng'
  },
  NewPassword: {
    en: 'New Password',
    vi: 'Mật khẩu mới'
  },
  NewPasswordVerify: {
    en: 'New Password Verify',
    vi: 'Nhập lại mật khẩu mới'
  },
  ChangePassword: {
    en: 'Change Password',
    vi: 'Đổi mật khẩu'
  },
  cronExpressionSync: {
    en: 'Cron Expression Sync',
    vi: 'Chu kỳ đồng bộ'
  },
  cronExpressionConfirm: {
    en: 'Cron Expression Confirm',
    vi: 'Chu kỳ xác nhận'
  },
  OnlyNotPrinted: {
    en: 'Only Not Printed',
    vi: 'Chỉ hiện các đơn chưa in'
  },
  All: {
    en: 'All',
    vi: 'Tất cả'
  },
  InPeriod: {
    en: 'In Period',
    vi: 'Trong khoảng thời gian'
  },
  AllTime: {
    en: 'All time',
    vi: 'Toàn thời gian'
  },
  Last30days: {
    en: 'Last 30 days',
    vi: '30 ngày qua'
  },
  Last7days: {
    en: 'Last 7 days',
    vi: '7 ngày qua'
  },
  Today: {
    en: 'Today',
    vi: 'Hôm nay'
  },
  ProductError: {
    en: 'Product Error',
    vi: 'Sản phẩm lỗi'
  },
  MatchingProduct: {
    en: 'Matching Product',
    vi: 'Gán sản phẩm'
  },
  CreateComboProduct: {
    en: 'Create Combo Product',
    vi: 'Tạo Combo'
  },
  ComboInvalid: {
    en: 'Combo Invalid',
    vi: 'Combo không hợp lệ'
  },
  Seller: {
    en: 'Seller',
    vi: 'Nhà bán hàng'
  },
  Ordered: {
    en: 'Ordered',
    vi: 'Đặt'
  },
  Received: {
    en: 'Received',
    vi: 'Nhận'
  },
  Auction: {
    en: 'Auction',
    vi: 'Đấu giá'
  },
  AuctionHistory: {
    en: 'Auction History',
    vi: 'Lịch sử đấu giá'
  },
  KeepCurrentPrice: {
    en: 'Keep Current Price',
    vi: 'Giữ nguyên giá hiện tại'
  },
  SellingPrice: {
    en: 'Selling price',
    vi: 'Giá bán'
  },
  MinPrice: {
    en: 'Min Price',
    vi: 'Giá min'
  },
  MaxPrice: {
    en: 'Max Price',
    vi: 'Giá max'
  },
  TikiFee: {
    en: "Tiki's fee",
    vi: 'Phí Tiki'
  },
  SyncTikiFee: {
    en: " Sync Tiki's fee",
    vi: 'Đồng bộ phí Tiki thu'
  },
  Earning: {
    en: 'Earning',
    vi: 'Lợi nhuận'
  },
  ProfitMargin: {
    en: 'Profit Margin',
    vi: 'Tỷ suất LN'
  },
  Schedule: {
    en: 'Schedule',
    vi: 'Lịch trình'
  },
  WhenEnemyPriceLessThanMin: {
    en: 'When Enemy < Min',
    vi: 'Khi ĐT < min'
  },
  EnemyPriceLessThanMin: {
    en: 'Enemy < Min',
    vi: 'Giá ĐT < min'
  },
  WhenNoEnemy: {
    en: 'When No Enemy',
    vi: 'Khi không ĐT'
  },
  FollowEnemyPrice: {
    en: 'Follow Enemy',
    vi: 'Bám giá'
  },
  CheckDeal: {
    en: 'Check Deal',
    vi: 'Check Deal'
  },
  total_sellers: {
    en: 'Total Sellers',
    vi: 'Tổng Sellers'
  },
  current_index: {
    en: 'Current Index',
    vi: 'VT hiện tại'
  },
  CurrentPrice: {
    en: 'Current Price',
    vi: 'Giá hiện tại'
  },
  last_index: {
    en: 'Last Index',
    vi: 'VT trước'
  },
  defeat: {
    en: 'Defeat',
    vi: 'Thua'
  },
  last_min: {
    en: 'Last Min',
    vi: 'Giá < nhất'
  },
  last_max: {
    en: 'Last Max',
    vi: 'Giá > nhất'
  },
  diff_with_min: {
    en: '< Min',
    vi: '< min'
  },
  last_auctioned: {
    en: 'Last Auctioned',
    vi: 'Ngày quét'
  },
  AutoCreateDeal: {
    en: 'Auto Create Deal',
    vi: 'Tự tạo deal'
  },
  On: {
    en: 'On',
    vi: 'Bật'
  },
  Off: {
    en: 'Off',
    vi: 'Tắt'
  },
  AuctionValue: {
    en: 'Auction',
    vi: 'Giảm'
  },
  Group: {
    en: 'Group',
    vi: 'Nhóm'
  },
  Payment: {
    en: 'Payment',
    vi: 'Thanh toán'
  },
  Keyword: {
    en: 'Keyword',
    vi: 'Từ khóa'
  },
  Comment: {
    en: 'Comment',
    vi: 'Bình luận'
  },
  cheapest: {
    en: 'Cheapest',
    vi: 'Rẻ nhất'
  },
  Enemy: {
    en: 'Enemy',
    vi: 'Đ.thủ'
  }
};

export default languages
