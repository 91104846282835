import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {RouteGuard} from './shared/guard/route.guard';

import {EntitiesComponent} from './entities/entities.component';

const routes: Routes = [
  {path: 'login', loadChildren: () => import('./pages/login/login.module').then(mod => mod.LoginModule)},
  {
    path: '',
    component: EntitiesComponent,
    children: [
      {
        path: 'admin',
        loadChildren: () => import('./entities/admin/admin.module').then(mod => mod.AdminModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'boss',
        loadChildren: () => import('./entities/boss/boss.module').then(mod => mod.BossModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'user',
        loadChildren: () => import('./entities/user/user.module').then(mod => mod.UserModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      }
    ]
  },
  {path: '**', redirectTo: 'login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
