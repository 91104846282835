import {Component, OnInit} from '@angular/core';

import {MessageService} from 'primeng/api';

import {ToastService} from './toast.service';
import {TranslateService} from '../../translate/translate.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html'
})
export class ToastComponent implements OnInit {

  success: any;
  error: any;

  constructor(
    private messageService: MessageService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    this.success = new Audio();
    this.success.src = `../../assets/audio/coins.mp3`;
    this.success.load();
    this.error = new Audio();
    this.error.src = `../../assets/audio/dont-think-so.mp3`;
    this.error.load();
  }

  ngOnInit(): void {
    this.toastService.getMessage().subscribe((message) => {
      this.messageService.clear();
      if (message) {
        switch (message.severity) {
          case 'success':
            this.success.play();
            window.navigator.vibrate([100]);

            if (!message.summary) {
              message.summary = 'Success';
            }
            if (!message.detail) {
              message.detail = 'DataSavedSuccessfully';
            }
            break;
          case 'error':
            this.error.play();
            window.navigator.vibrate([100, 30, 100, 30]);

            if (!message.summary) {
              message.summary = 'Oops';
            }
            if (!message.detail) {
              message.detail = 'ServiceUnavailable';
            }
            break;
        }

        message.summary = this.translateService.get(message.summary);
        message.detail = this.translateService.get(message.detail);
        this.messageService.add(message);
      }
    });
  }

}
