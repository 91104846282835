<p-menubar [model]="items" styleClass="iam-menu p-mb-2">
  <p-button icon="pi pi-unlock" styleClass="p-button-rounded" (onClick)="changePassword=true;"></p-button>
  <p-button icon="pi pi-sign-out" styleClass="p-button-rounded p-button-danger" (onClick)="logout()"></p-button>
</p-menubar>

<div class="led-box" *ngIf="type === 'user'">
  <div [className]="socket_cacbonat_active > 0 ? 'led-green' : 'led-green off'"></div>
</div>

<form [formGroup]="changePasswordForm" (ngSubmit)="setPassword()" autocomplete="off">
  <p-dialog header="{{ 'ChangePassword'|translate }}" [(visible)]="changePassword" [style]="{width: '500px'}" [resizable]="false" position="top">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12">
        <label for="newPassword">{{ 'NewPassword'|translate }}<span class="required">*</span></label>
        <input pInputText type="text" id="newPassword" formControlName="newPassword">
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label for="newPasswordVerify">{{ 'NewPasswordVerify'|translate }}<span class="required">*</span></label>
        <input pInputText type="text" id="newPasswordVerify" formControlName="newPasswordVerify">
      </div>
    </div>
    <p-footer>
      <p-button label="{{ 'Save'|translate }}" styleClass="p-button-raised" type="submit" [disabled]="changePasswordForm.invalid"></p-button>
      <p-button label="{{ 'Close'|translate }}" styleClass="p-button-raised p-button-text p-button-plain" (onClick)="changePassword=false"></p-button>
    </p-footer>
  </p-dialog>
</form>
