import {Injectable} from '@angular/core';

@Injectable({
  providedIn: `root`
})
export class UserService {

  static readonly app = 'iam-cleo';

  static setUser(user: any): void {
    localStorage.setItem(`${this.app}_user`, JSON.stringify(user));
  }

  static getUser(): any {
    return JSON.parse(localStorage.getItem(`${this.app}_user`) || '{}');
  }

  static setRouterState(routerState: string): void {
    localStorage.setItem(`${this.app}_routerState_${this.getUser()._id}`, routerState);
  }

  static getRouterState(): string {
    return localStorage.getItem(`${this.app}_routerState_${this.getUser()._id}`) || '';
  }

  static setPrintMinCode(printMinCode: boolean | undefined): void {
    localStorage.setItem(`${this.app}_printMinCode_${this.getUser()._id}`, printMinCode ? `Y` : `N`);
  }

  static getPrintMinCode(): boolean {
    return localStorage.getItem(`${this.app}_printMinCode_${this.getUser()._id}`) === `Y`;
  }

}
