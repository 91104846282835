import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ToastModule} from 'primeng/toast';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {MenubarModule} from 'primeng/menubar';
import {DialogModule} from 'primeng/dialog';

import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';

import {TranslatePipe} from './translate/translate.pipe';

import {MenuComponent} from './components/menu/menu.component';
import {ProgressComponent} from './components/progress/progress.component';
import {ToastComponent} from './components/toast/toast.component';
import {ConfirmComponent} from './components/confirm/confirm.component';

@NgModule({
  declarations: [
    TranslatePipe,

    MenuComponent,
    ProgressComponent,
    ToastComponent,
    ConfirmComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    ToastModule,
    ConfirmPopupModule,
    ButtonModule,
    InputTextModule,
    MenubarModule,
    DialogModule
  ],
  providers: [
    MessageService,
    ConfirmationService
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    ButtonModule,
    InputTextModule,
    DialogModule,

    TranslatePipe,

    MenuComponent,
    ProgressComponent,
    ToastComponent,
    ConfirmComponent
  ]
})
export class SharedModule {
}
