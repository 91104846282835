import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

  private readonly progress: BehaviorSubject<boolean>;

  constructor() {
    this.progress = new BehaviorSubject<boolean>(false);
  }

  getProgress(): BehaviorSubject<boolean> {
    return this.progress;
  }

  setProgress(progress: boolean): void {
    this.progress.next(progress);
  }
}
